<template>
  <main>
    <div class="container mx-auto">
      <div class="mb-4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4">
        <form method="post" @submit.prevent="addNew">
          <div class="flex flex-col">
            <div class="mb-6" id="message"></div>
            <div class="mb-4">
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
                for="event"
              >
                Event
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                id="name"
                type="text"
                placeholder="Event"
              />
            </div>
            <div class="flex items-center justify-between">
              <button
                v-if="!loading"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                type="submit"
              >
                Submit
              </button>
              <button
                v-if="loading"
                class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                type="submit"
                disabled
              >
                Submitting...
              </button>
            </div>
          </div>
        </form>
      </div>
      <div id="recipients" style="overflow: scroll;">
        <table id="tab"></table>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "AddResale",
  data() {
    return {
      sidebarOpen: true,
      name: "",
      error: false,
      loading: false
    };
  },
  methods: {
    addNew() {
      this.error = false;
      var nameevent = document.getElementById("name").value;
      console.log(nameevent);
      this.loading = true;
      this.$axios
        .get(`https://spaceseats.io/api/ticket/lysted?name=${nameevent}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
          },
          mode: "no-cors"
        })
        .then(res => {
          console.log(res.data.data);
          document.getElementById("tab").innerHTML = res.data.data;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          document.getElementById("message").innerHTML = `
              <span style="color:#a94442;font-weight:bold">Something went wrong</span>
            `;
        });
      setTimeout(() => {
        document.getElementById("message").innerHTML = ``;
      }, 6000);
    }
  }
};
</script>
